import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import { createUseStyles } from 'react-jss';
import { Sizes } from '../../../common/enums';
import { onEnter } from '../../../common/utils/keyboardUtils';
import { useGenericStyles } from '../../../hooks';
import { COLORS } from '../../../theme';
import { IColumn } from '../../../types';

interface IProps<T> {
  records: T[];
  renderDelete?: (record: T, size: Sizes) => JSX.Element | undefined;
  onClick?: (record: T) => void;
  columns: IColumn<T>[];
}

const useStyle = createUseStyles({
  card: {
    border: `1px solid ${COLORS.borderGrey}`,
  },
  content: {
    padding: '1rem',
    marginBottom: '-1rem',
  },
  delete: {
    marginTop: 'auto',
  },
  row: {
    marginBottom: '0.5rem',
    display: 'flex',
  },
});

export const MyRequestsMobileTable = <T,>({
  onClick,
  renderDelete,
  records,
  columns,
}: PropsWithChildren<IProps<T>>) => {
  const C = useStyle();
  const G = useGenericStyles();
  const onItemClick = (record: T) => () => onClick?.(record);
  const onKeyPress = (record: T) => onEnter(onItemClick(record));

  return (
    <>
      {records.map((record) => (
        <div
          //@ts-ignore
          key={record.id}
          className={classNames(C.card, G.spacing.marginBottom.ML, 'focusable')}
          onClick={onItemClick(record)}
          onKeyPress={onKeyPress(record)}
          tabIndex={0}
        >
          <div className={C.content}>
            <div className={C.row}>
              <div className={classNames(G.flex.column, G.flex.grow)}>
                {columns.map(({ renderer, name }, i) =>
                  renderer ? <div key={`${i}-${String(name)}`}>{renderer(record[name], record, true)}</div> : null,
                )}
              </div>
              {renderDelete && <div className={C.delete}>{renderDelete(record, Sizes.Medium)}</div>}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
