import { Actions, TableActions } from './generic.actions';
import {
  ISgwProject,
  ISgwRequest,
  ILinkRequestPartner,
  IPartner,
  IRequestFile,
  IAttachment,
  IAttachmentBE,
  Category,
  IRequestMessageAttachment,
  IPostMessage,
  IRequestMessages,
  IRequestRescheduling,
  IPhaseCostQuarter,
} from '../../types';
import { ConflictActionsClass } from './conflicts.actions';

class MessagesActionClass extends Actions {
  fetch = this.createPayloadAction<{ requestId: string }>('fetch');
  set = this.createPayloadAction<IRequestMessages>('set');
  create = this.createPayloadAction<IPostMessage>('create');
  uploadAttachment = this.createPayloadAction<File>('uploadAttachment');
  setAttachment = this.createPayloadAction<Partial<IRequestMessageAttachment>[]>('setAttachment');
}
class RescheduleRequestClass extends Actions {
  create =
    this.createPayloadAction<{ requestId: string; rescheduling: IRequestRescheduling; requestRescheduleId?: number }>(
      'create',
    );
  fetch = this.createPayloadAction<{ requestId: string }>('fetch');
  remove = this.createPayloadAction<{ requestId: string; requestRescheduleId: string }>('remove');
  set = this.createPayloadAction<IRequestRescheduling>('set');
}

class PhaseCostQuartersClass extends Actions {
  fetch = this.createPayloadAction<string>('fetch');
  set = this.createPayloadAction<IPhaseCostQuarter[]>('set');
}

class AttachmentsActionsClass extends Actions {
  add = this.createPayloadAction<IAttachmentBE>('add');
  clear = this.createAction('clear');
  remove = this.createPayloadAction<{ requestId: number; attachmentId: number }>('remove');
  fetch = this.createPayloadAction<string>('fetch');
  link = this.createPayloadAction<{ requestId: number; attachment: Partial<IAttachment> }>('link');
  upload = this.createPayloadAction<{ requestId: number; file: IRequestFile; category?: Category }>('upload');
  set = this.createPayloadAction<IAttachmentBE[]>('set');
}

class SgwRequestActionsClass extends Actions {
  attachments = new AttachmentsActionsClass(`${this.prefix}.attachments`);
  conflicts = new ConflictActionsClass(`${this.prefix}.conflicts`);
  create = this.createPayloadAction<{ request: Partial<ISgwRequest>; partner: IPartner }>('create');
  copy = this.createPayloadAction<{ request: Partial<ISgwRequest> }>('copy');
  fetch = this.createPayloadAction<string>('fetch');
  fetchProjects = this.createAction('fetchProjects');
  linkPartner = this.createPayloadAction<ILinkRequestPartner>('linkPartner');
  save = this.createPayloadAction<{ step: number; request: Partial<ISgwRequest>; id?: string }>('save');
  set = this.createPayloadAction<ISgwRequest>('set');
  setProjects = this.createPayloadAction<ISgwProject[]>('setProjects');
  list = new TableActions<ISgwRequest>(`${this.prefix}.list`);
  messages = new MessagesActionClass(`${this.prefix}.messages`);
  rescheduling = new RescheduleRequestClass(`${this.prefix}.rescheduleRequest`);
  phaseCostQuarters = new PhaseCostQuartersClass(`${this.prefix}.phaseCostQuarters`);
}

export const SgwRequestActions = new SgwRequestActionsClass('sgwRequest');
