import { Sizes } from 'antwerp-core-react-branding';
import classNames from 'classnames';
import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  createDeleteDraftRequestAction,
  createDeleteTempRequestAction,
} from '../../../common/actions/creators/requests';
import { WORKFLOW_STATE_DRAFT } from '../../../common/constants';
import { Levels } from '../../../common/enums';
import * as TempRequest from '../../../common/tempRequestStorage';
import { formatToDisplayDate } from '../../../common/utils/dateUtils';
import {
  calculateUntilDate,
  getRequestDetailsPath,
  getRequestState,
  getSgwRequestDetailsPath,
  getSgwWorkflowInitialPath,
  getWorkflowInitialPath,
  locationFormatter,
  periodFormatter,
  stateFormatter,
} from '../../../common/utils/requestUtils';
import { getSgwRequestMainLocation } from '../../../common/utils/sgwRequestUtils';
import { getLocalUrl } from '../../../common/utils/urlUtils';
import { SgwRequestActions } from '../../../store/actions';
import { CarFreeZoneActions } from '../../../store/actions/carFreeZone.actions';
import { getDraftRequests, getSubmittedRequests, selectCarFreeZones, selectSgwStates } from '../../../store/selectors';
import { getSgwDraftRequests, getSgwSubmittedRequests } from '../../../store/selectors/sgw';
import { translate, translateIgnoreTS } from '../../../translations/translate';
import {
  IColumn,
  IPublicDomainIntake,
  IRequest,
  ISgwPhaseMainLocation,
  ISgwRequest,
  MessageState,
  SgwRequestState,
} from '../../../types';
import { CheckBox, Icon, MyRequestsMobileCell, MyRequestsStateIcons, StateLabel } from '../../atoms';
import { Visible } from '../../common/layout/Visible.component';
import { MyRequestsList } from '../../molecules';

const useStyle = createUseStyles({
  dateColumnWidth: {
    width: '10%',
  },
  stateColumnWidth: {
    width: '20%',
  },
  flagColumnWidth: {
    width: '20px',
  },
  removeLeftMargin: {
    marginLeft: 0,
  },
  requestFlagContainer: {
    marginBottom: '0.5rem',
    display: 'flex',
  },
});

const needActionSgwRequestFilter = (
  sgwRequests: ISgwRequest[],
  showOnlyRequestsThatNeedAction: boolean = false,
): ISgwRequest[] => {
  if (!showOnlyRequestsThatNeedAction) {
    return sgwRequests;
  }
  return sgwRequests.filter((x) => x.messageState === MessageState.AwaitingFeedback);
};

const needActionRequestFilter = (
  sgwRequests: IRequest[],
  showOnlyRequestsThatNeedAction: boolean = false,
): IRequest[] => {
  if (!showOnlyRequestsThatNeedAction) {
    return sgwRequests;
  }
  return sgwRequests.filter((x) => x.messageState === MessageState.AwaitingFeedback);
};

export const MyRequestsPage: FC = () => {
  const C = useStyle();
  const dispatch = useDispatch();
  const [showOnlyRequestsThatNeedAction, setShowOnlyRequestsThatNeedAction] = useState<boolean>(false);
  const [tempRequest, setTempRequest] = useState<IRequest>();
  const draftRequests = needActionRequestFilter(useSelector(getDraftRequests), showOnlyRequestsThatNeedAction);
  const submittedRequests = needActionRequestFilter(useSelector(getSubmittedRequests), showOnlyRequestsThatNeedAction);
  const sgwSubmittedRequests = needActionSgwRequestFilter(
    useSelector(getSgwSubmittedRequests),
    showOnlyRequestsThatNeedAction,
  );
  const sgwDraftRequests = needActionSgwRequestFilter(useSelector(getSgwDraftRequests), showOnlyRequestsThatNeedAction);
  const carFreeZones = useSelector(selectCarFreeZones);
  const sgwStates = useSelector(selectSgwStates);

  const columns = (type?: string): IColumn<IRequest>[] => [
    {
      label: translate('general.sent'),
      name: 'createdAt',
      renderer: (createdAt: string, _, isMobile) => (isMobile ? null : formatToDisplayDate(createdAt)),
      className: C.dateColumnWidth,
      visible: type !== 'draft',
    },
    {
      label: '',
      name: 'messageState',
      renderer: (_, request, isMobile) => (
        <div className={classNames(isMobile && C.requestFlagContainer)}>
          <MyRequestsStateIcons
            messageState={request?.messageState}
            emergencyProcedure={request?.emergencyProcedure}
            className={classNames(isMobile && C.removeLeftMargin)}
          />
        </div>
      ),
      className: C.flagColumnWidth,
    },
    {
      label: translate('myRequests.mobileView.period'),
      name: 'dateFrom',
      renderer: (_, request, isMobile) =>
        isMobile ? (
          <MyRequestsMobileCell
            title={translate('myRequests.mobileView.period')}
            value={request ? periodFormatter(request) : ''}
          />
        ) : null,
      hideInDesktop: true,
    },
    {
      label: translate('general.from'),
      name: 'dateFrom',
      renderer: (dateFrom: string, _, isMobile) => (isMobile ? null : formatToDisplayDate(dateFrom)),
      className: C.dateColumnWidth,
    },
    {
      label: translate('general.to'),
      name: 'dateUntil',
      renderer: (_, request, isMobile) => (isMobile ? null : calculateUntilDate(request)),
      className: C.dateColumnWidth,
    },
    {
      label: translate('general.locations'),
      name: 'publicDomainIntakes',
      renderer: (pdis: IPublicDomainIntake[], _, isMobile) =>
        isMobile ? (
          <MyRequestsMobileCell
            title={translate('myRequests.mobileView.location')}
            value={locationFormatter(carFreeZones)(pdis)}
          />
        ) : (
          locationFormatter(carFreeZones)(pdis)
        ),
    },
    {
      label: translate('general.referenceId'),
      name: 'referenceId',
      renderer: (referenceId: string, _, isMobile) =>
        isMobile ? (
          <MyRequestsMobileCell title={translate('myRequests.mobileView.reference')} value={referenceId} />
        ) : (
          referenceId
        ),
    },
    {
      label: translate('general.status'),
      name: 'state',
      renderer: (_, request, isMobile) => {
        const value = request ? stateFormatter(getRequestState(request)) : '';
        return isMobile ? (
          <MyRequestsMobileCell title={translate('myRequests.mobileView.status')} value={value} />
        ) : (
          value
        );
      },
      className: C.stateColumnWidth,
    },
  ];

  const sgwColumns = (type?: string): IColumn<ISgwRequest>[] => [
    {
      label: translate('general.sent'),
      name: 'submittedDate',
      renderer: (submittedDate: string, _, isMobile) => (isMobile ? null : formatToDisplayDate(submittedDate)),
      className: C.dateColumnWidth,
      visible: type !== 'draft',
    },
    {
      label: '',
      name: 'messageState',
      renderer: (messageState: MessageState, _, isMobile) => (
        <div className={classNames(isMobile && C.requestFlagContainer)}>
          <MyRequestsStateIcons messageState={messageState} className={classNames(isMobile && C.removeLeftMargin)} />
        </div>
      ),
      className: C.flagColumnWidth,
    },
    {
      label: translate('general.from'),
      name: 'dateFrom',
      renderer: (dateFrom: string, _, isMobile) => (isMobile ? null : formatToDisplayDate(dateFrom)),
      className: C.dateColumnWidth,
    },
    {
      name: 'dateFrom',
      renderer: (_, request, isMobile) =>
        isMobile ? (
          <MyRequestsMobileCell
            title={translate('myRequests.mobileView.period')}
            value={request ? `${request.dateFrom} / ${request.dateUntil}` : ''}
          />
        ) : null,
      hideInDesktop: true,
    },
    {
      label: translate('general.to'),
      name: 'dateUntil',
      renderer: (dateUntil: string, _, isMobile) => (isMobile ? null : formatToDisplayDate(dateUntil)),
      className: C.dateColumnWidth,
    },
    {
      label: translate('general.typeOfWork'),
      name: 'workType',
      renderer: (workType: string, _, isMobile) => {
        const translation = translateIgnoreTS(`sgw.workType.type.options.${workType}`);
        return isMobile ? (
          <MyRequestsMobileCell title={translate('myRequests.mobileView.typeOfWork')} value={translation} />
        ) : (
          translation
        );
      },
    },
    {
      label: translate('general.locations'),
      name: 'mainLocation',
      renderer: (location: ISgwPhaseMainLocation, _, isMobile) =>
        isMobile ? (
          <MyRequestsMobileCell
            title={translate('myRequests.mobileView.location')}
            value={getSgwRequestMainLocation(location)}
          />
        ) : (
          getSgwRequestMainLocation(location)
        ),
    },
    {
      label: translate('general.referenceId'),
      name: 'referenceId',
      renderer: (referenceId: string, _, isMobile) =>
        isMobile ? (
          <MyRequestsMobileCell title={translate('myRequests.mobileView.reference')} value={referenceId} />
        ) : (
          referenceId
        ),
    },
    {
      label: translate('general.status'),
      name: 'state',
      className: C.stateColumnWidth,
      renderer: (state: string, _, isMobile) => {
        const currentState = sgwStates?.find((s) => s.state === state);
        const value = <StateLabel state={state} name={currentState?.name} small />;
        return isMobile ? (
          <MyRequestsMobileCell title={translate('myRequests.mobileView.status')} value={value} />
        ) : (
          value
        );
      },
    },
    {
      label: '',
      name: 'actions',
      renderer: (_, request, isMobile) => {
        return (
          request && (
            <Icon.Copy
              level={Levels.Primary}
              size={Sizes.Small}
              name={translate('myRequests.copyRequest')}
              title={translate('myRequests.copyRequest')}
              onClick={() => {
                dispatch(SgwRequestActions.copy({ request }));
              }}
            />
          )
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(SgwRequestActions.list.fetch());
    setTempRequest(TempRequest.getTempRequestExceptSaved());
    dispatch(CarFreeZoneActions.get());
  }, [dispatch]);

  const onDeleteTempRequest = () => {
    dispatch(createDeleteTempRequestAction());
    setTempRequest(undefined);
  };

  const onDelete = (request: IRequest) => dispatch(createDeleteDraftRequestAction(request));
  const history = useNavigate();
  const onClick = (request: IRequest) => {
    const url =
      request.state?.state === WORKFLOW_STATE_DRAFT || !request.state
        ? getWorkflowInitialPath(request)
        : getRequestDetailsPath(request);
    history(getLocalUrl(url));
  };

  const onClickSgw = (request: ISgwRequest) => {
    const url =
      request.state === SgwRequestState.draft ? getSgwWorkflowInitialPath(request) : getSgwRequestDetailsPath(request);
    history(getLocalUrl(url));
  };

  return (
    <section>
      <CheckBox
        checked={showOnlyRequestsThatNeedAction}
        label={translate('myRequests.showOnlyRequestsThatNeedAction')}
        name={'needAction'}
        onChange={(e: any) => {
          setShowOnlyRequestsThatNeedAction(!showOnlyRequestsThatNeedAction);
        }}
      />

      <Visible visible={!!tempRequest}>
        <MyRequestsList<IRequest>
          onDelete={onDeleteTempRequest}
          records={tempRequest && !showOnlyRequestsThatNeedAction ? [tempRequest] : []}
          title={translate('myRequests.unsavedRequests')}
          subtitle={translate('myRequests.asignSubtitle')}
          columns={columns('draft')}
          onClick={onClick}
        />
      </Visible>
      <MyRequestsList<ISgwRequest>
        records={sgwDraftRequests}
        title={translate('myRequests.draftRequests')}
        subtitle={translate('myRequests.sgwSubtitle')}
        columns={sgwColumns('draft')}
        showEmptyView={false}
        onClick={onClickSgw}
      />
      <MyRequestsList<IRequest>
        onDelete={onDelete}
        records={draftRequests}
        subtitle={translate('myRequests.asignSubtitle')}
        columns={columns('draft')}
        onClick={onClick}
      />
      <MyRequestsList<ISgwRequest>
        records={sgwSubmittedRequests}
        title={translate('myRequests.submittedRequests')}
        subtitle={translate('myRequests.sgwSubtitle')}
        columns={sgwColumns()}
        showEmptyView={false}
        onClick={onClickSgw}
      />
      <MyRequestsList<IRequest>
        records={submittedRequests}
        subtitle={translate('myRequests.asignSubtitle')}
        columns={columns()}
        onClick={onClick}
      />
    </section>
  );
};
