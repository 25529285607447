import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { PhaseActions } from '../actions/phase.actions';
import { genericErrorHandler } from './errors.sagas';
import { SnackBarActions } from '../actions/Snackbar.actions';
import { phaseApi } from '../api/phase.api';
import { ICall, ISgwPhase, ISgwPhaseBE, ISgwRequest } from '../../types';
import { translate } from '../../translations/translate';
import { SgwRequestActions } from '../actions';
import { mapToGeoDrawingsBE } from '../../common/utils/sgwPhaseUtils';
import { getPhase, getRequest, getStandardPhases } from '../selectors/sgw';

export function* onDelete({ payload }: ReturnType<typeof PhaseActions.delete>): SagaIterator {
  const phases: ISgwPhase[] = yield select(getStandardPhases(payload.requestId));
  const request: ISgwRequest = yield select(getRequest(payload.requestId));
  const phase: ISgwPhase = yield select(getPhase(payload.id));

  yield call(phaseApi.delete, payload.requestId, payload.id);
  yield put(PhaseActions.fetchList(payload.requestId));
  if (payload.index === 0 && !phase.isRecurringPhaseTemplate && phases.length > 1)
    yield put(
      PhaseActions.save({
        requestId: payload.requestId,
        hideSnackbar: true,
        phase: { ...phases[1], dateFrom: request?.dateFrom },
      }),
    );
  yield put(SgwRequestActions.fetch(payload.requestId));
}

export function* onSave({
  payload: { requestId, phase, hideSnackbar },
}: ReturnType<typeof PhaseActions.save>): SagaIterator {
  try {
    const geoDrawings = mapToGeoDrawingsBE(phase.sgwGeoDrawings);

    const response: ICall<typeof phaseApi.save> = yield call(phaseApi.save, requestId, {
      ...phase,
      sgwGeoDrawings: geoDrawings,
    });

    if (!hideSnackbar) {
      yield put(SnackBarActions.setSuccess(translate('sgw.phases.success')));
    }

    yield put(PhaseActions.set(response!.data.data));
  } catch (e) {
    if (!hideSnackbar) {
      yield put(SnackBarActions.setFailure(translate('sgw.phases.failure')));
    }
  }
}

export function* onFetchList({ payload }: ReturnType<typeof PhaseActions.fetchList>): SagaIterator {
  const response: ICall<typeof phaseApi.fetchList> = yield call(phaseApi.fetchList, payload);
  const list: ISgwPhaseBE[] = response!.data.data;
  yield put(
    PhaseActions.setList(
      list.map((phase) => ({
        ...phase,
        sgwTrafficMeasures: phase.sgwTrafficMeasures?.map((value) => `${value}`) || [],
        sgwNatureOfWorks: phase.sgwNatureOfWorks?.map((value) => `${value}`) || [],
      })),
    ),
  );
}

export function* phaseSaga(): SagaIterator {
  yield takeEvery(PhaseActions.delete, genericErrorHandler(onDelete));
  yield takeEvery(PhaseActions.save, genericErrorHandler(onSave));
  yield takeEvery(PhaseActions.fetchList, genericErrorHandler(onFetchList));
}
