import { useMediaQuery } from '@mui/material';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import { createUseStyles } from 'react-jss';
import { Levels, Sizes } from '../../../common/enums';
import { useGenericStyles } from '../../../hooks';
import { COLORS } from '../../../theme';
import { translate } from '../../../translations/translate';
import { IColumn } from '../../../types';
import { Icon } from '../../atoms';
import { Visible } from '../../common/layout/Visible.component';
import { MyRequestsDesktopTable } from './MyRequestsDesktopTable.component';
import { MyRequestsMobileTable } from './MyRequestsMobileTable.component';

interface IProps<T> {
  records: T[];
  title?: string | JSX.Element;
  subtitle?: string | JSX.Element;
  onDelete?(record: T): void;
  onClick?(record: T): void;
  columns: IColumn<T>[];
  showEmptyView?: boolean;
}

const useStyles = createUseStyles({
  emptyView: {
    display: 'flex',
    border: `1px solid ${COLORS.borderGrey}`,
    borderCollapse: 'collapse',
    width: '100%',
    padding: '0.75rem',
  },
});

const EmptyView = () => <div className={useStyles().emptyView}>{translate('myRequests.noRequest')}</div>;

export const MyRequestsList = <T,>({
  title,
  subtitle,
  onDelete,
  showEmptyView = true,
  ...props
}: PropsWithChildren<IProps<T>>) => {
  const G = useGenericStyles();
  const isDesktopView = useMediaQuery('(min-width:45rem)');

  const renderDelete = (record: T, size: Sizes) =>
    onDelete && (
      <Icon.Delete
        level={Levels.Secondary}
        size={size}
        name={translate('general.clear')}
        onClick={() => onDelete(record)}
      />
    );

  return (
    <div className={classNames(G.spacing.marginBottom.LG, G.spacing.marginTop.ML)}>
      {title && <h3 className={G.spacing.marginBottom.ML}>{title}</h3>}
      {subtitle && <h6 className={G.spacing.marginBottom.XS}>{subtitle}</h6>}
      <Visible visible={props.records.length > 0} defaultComponent={showEmptyView && <EmptyView />}>
        <Visible
          visible={isDesktopView}
          defaultComponent={<MyRequestsMobileTable renderDelete={renderDelete} {...props} />}
        >
          <MyRequestsDesktopTable renderDelete={renderDelete} {...props} />
        </Visible>
      </Visible>
    </div>
  );
};
