import {
  IApiPromise,
  IAttachment,
  IAttachmentBE,
  IFile,
  IPhaseCostQuarter,
  IPostMessage,
  IRequestMessage,
  IRequestMessages,
  IRequestRescheduling,
  ISgwProject,
  ISgwRequestBE,
} from '../../types';
import axios from 'axios';
import { getApiUserUrl, getApiGeneralUrl } from '../../common/utils/apiUtils';

export const SgwRequestApi = {
  copy: (sgwRequestId: number): IApiPromise<ISgwRequestBE> => {
    return axios.post(getApiUserUrl(`/sgw/requests/${sgwRequestId}/copy`));
  },
  deleteAttachment: (requestId: string, attachmentId: string): IApiPromise<void> =>
    axios.delete(getApiUserUrl(`/sgw/requests/${requestId}/sgw_attachments/${attachmentId}`)),
  fetch: (id: string): IApiPromise<ISgwRequestBE> => axios.get(getApiUserUrl(`/sgw/requests/${id}`)),
  fetchAttachments: (id: string): IApiPromise<IAttachmentBE[]> =>
    axios.get(getApiUserUrl(`/sgw/requests/${id}/sgw_attachments`)),
  fetchProjects: (): IApiPromise<ISgwProject[]> => axios.get(getApiUserUrl('/sgw/projects')),
  save: (request: Partial<ISgwRequestBE>): IApiPromise<ISgwRequestBE> => {
    const { id, ...requestData } = request;
    return id
      ? axios.patch(getApiUserUrl(`/sgw/requests/${id}`), requestData)
      : axios.post(getApiUserUrl('/sgw/requests'), request);
  },
  saveAttachmentToRequest: (requestId: string, { id, ...data }: Partial<IAttachment>): IApiPromise<IAttachmentBE> =>
    id
      ? axios.patch(getApiUserUrl(`/sgw/requests/${requestId}/sgw_attachments/${id}`), data)
      : axios.post(getApiUserUrl(`/sgw/requests/${requestId}/sgw_attachments`), data),
  fetchList: (): IApiPromise<ISgwRequestBE[]> => axios.get(getApiUserUrl(`/sgw/requests`)),
  fetchMessages: (requestId: string): IApiPromise<IRequestMessages> =>
    axios.get(getApiUserUrl(`/sgw/requests/${requestId}/messages`)),
  createMessage: (message: IPostMessage): IApiPromise<IRequestMessage> =>
    axios.post(getApiUserUrl(`/sgw/requests/${message.requestId}/messages`), message.message),
  saveFile: (file: FormData): IApiPromise<IFile[]> =>
    axios.post(getApiGeneralUrl(`storage`), file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),

  fetchRequestReschedulings: (requestId: string): IApiPromise<IRequestRescheduling> =>
    axios.get(getApiUserUrl(`/sgw/requests/${requestId}/rescheduling_requests`)),

  createRequestReschedulings: (
    requestId: string,
    rescheduling: IRequestRescheduling,
    requestReschedulingId?: number,
  ): IApiPromise<IRequestRescheduling> =>
    requestReschedulingId
      ? axios.patch(
          getApiUserUrl(`/sgw/requests/${requestId}/rescheduling_requests/${requestReschedulingId}`),
          rescheduling,
        )
      : axios.post(getApiUserUrl(`/sgw/requests/${requestId}/rescheduling_requests`), rescheduling),
  deleteRequestReschedulings: (requestId: string, requestReschedulingId: string): IApiPromise<IRequestRescheduling> =>
    axios.delete(getApiUserUrl(`/sgw/requests/${requestId}/rescheduling_requests/${requestReschedulingId}`)),

  fetchPhaseCostQuarters: (requestId: string): IApiPromise<IPhaseCostQuarter[]> =>
    axios.get(getApiUserUrl(`/sgw/requests/${requestId}/cost`)),

  checkIsValidGipodId: (gipodId: number): IApiPromise<any[]> =>
    axios.get(getApiUserUrl(`/gipod/groundworks/${gipodId}/exist`)),
};
